
const digest = 'e75c99d81240db9e4002e1b233e35023bb72b37aff2e9f31f3a9cb9e0a8a587e';
const css = `._incrementer_1ocs1_1 {
  position: absolute;
  z-index: 10;

  align-items: center;
  background: var(--color-white);
  border-bottom: 1px solid var(--color-white);
  border-radius: 4px;
  border-top: 1px solid var(--color-white);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  color: var(--color-neutral-10);
  display: flex;
  height: 100%;
  justify-content: center;
  line-height: 1;
  text-align: center;
  transform: translateZ(0);
  width: 100%;
}

._button_1ocs1_21 {
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  fill: var(--color--neutral-10);
  height: 100%;
  justify-content: center;
  outline: none;
  padding: 0.25rem;
  transition: all 0.25s ease-in-out;
  width: 100%;
}

._button_1ocs1_21 rect {
  fill: var(--color--neutral-10);
  transform-origin: bottom center;
  transition: fill 0.25s ease-in-out;
}

._button_1ocs1_21:hover rect {
  fill: var(--color-neutral-10);
}

._button_1ocs1_21._is-disabled_1ocs1_46 rect,
._button_1ocs1_21._is-disabled_1ocs1_46:hover rect {
  fill: var(--color-legacy-gray-3);
}

._field_1ocs1_51 {
  border: none;
  color: var(--color-neutral-10);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  height: 100%;
  text-align: center;
  transition: background 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  width: 1.5rem;
  /* hide up/down arrows */
  -moz-appearance: textfield;
}

._field_1ocs1_51::placeholder {
  color: var(--color--neutral-10);
}

._field_1ocs1_51:hover,
._field_1ocs1_51:focus {
  background: var(--color-legacy-gray-1);
  cursor: text;
  outline: none;
}

/* hide up/down arrows */
._field_1ocs1_51::-webkit-outer-spin-button,
._field_1ocs1_51::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"incrementer":"_incrementer_1ocs1_1","button":"_button_1ocs1_21","is-disabled":"_is-disabled_1ocs1_46","isDisabled":"_is-disabled_1ocs1_46","field":"_field_1ocs1_51"};
export { css, digest };
  