
const digest = '3ef34e352729baff3f84ece90afc9dbc5d230cb5433e5584f660cd2d71e0797e';
const css = `._fieldGroupLabel_1y59t_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-10);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"fieldGroupLabel":"_fieldGroupLabel_1y59t_1"};
export { css, digest };
  